import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`23rd June 2021`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A new module `}<inlineCode parentName="p">{`armeria-graphql`}</inlineCode>{` integrates with `}<a parentName="p" {...{
            "href": "https://www.graphql-java.com/"
          }}>{`GraphQL Java`}</a>{` to let you serve
GraphQL requests. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3318"
          }}>{`#3318`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3373"
          }}>{`#3373`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...;
sb.service("/graphql",
    GraphqlService.builder()
                  .schemaFile(schemaFiles)
                  .runtimeWiring(w -> ...)
                  .configureDataLoaderRegistry(registry -> ...)
                  ...
                  .build());
`}</code></pre>
        <Tip mdxType="Tip">
          <p parentName="li"><inlineCode parentName="p">{`armeria-graphql`}</inlineCode>{` is currently experimental. We're looking forward to your feedback.`}</p>
        </Tip>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now transform the objects of `}<a parentName="p" {...{
            "href": "type://HttpRequest:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html"
          }}>{`type://HttpRequest`}</a>{` and `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{` using a function.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3606"
          }}>{`#3606`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3624"
          }}>{`#3624`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpRequest req1 = ...;
HttpRequest req2 = 
    req1.mapHeaders(headers -> headers.toBuilder()
                                      .add("TraceId", "1")
                                      .build())
        .mapData(data -> HttpData.ofUtf8(data.toStringUtf8() + '\\n'))
        .mapTrailers(trailers -> trailers.toBuilder()
                                         .add("trailer-key", "val")
                                         .build())

HttpResponse res1 = ...;
HttpResponse res2 = 
    res1.mapHeaders(headers -> headers.toBuilder()
                                      .add("SessionId", "123")
                                      .build())
        .mapInformational(informational -> {
            return informational.withMutations(builder -> {
                builder.add(HttpHeaderNames.USER_AGENT, "Armeria");
            });
        })
        .mapData(data -> HttpData.ofUtf8(data.toStringUtf8()
                                 .replaceAll("\\n", "<br/>")))
        .mapTrailers(trailers -> trailers.toBuilder()
                                         .add("result", "0")
                                         .build());
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now collect the all elements of a `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` with `}<a parentName="p" {...{
            "href": "type://StreamMessage#collect():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html#collect()"
          }}>{`type://StreamMessage#collect()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3603"
          }}>{`#3603`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`StreamMessage<Integer> stream = StreamMessage.of(1, 2, 3);
CompletableFuture<List<Integer>> collected = stream.collect();
assert collected.join().equals(List.of(1, 2, 3));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can exclude a `}<a parentName="p" {...{
            "href": "type://Route:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Route.html"
          }}>{`type://Route`}</a>{` from another `}<a parentName="p" {...{
            "href": "type://Route:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Route.html"
          }}>{`type://Route`}</a>{` when configuring a service. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2737"
          }}>{`#2737`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3562"
          }}>{`#3562`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = ...;
sb.route()
  .pathPrefix("/api")
  .exclude("prefix:/api/admin")
  .exclude(Route.builder()
                .pathPrefix("/api/v1")
                .consumes(MediaType.JSON)
                .build())
  .build(apiService);

sb.routeDecorator()
  .pathPrefix("/api")
  .exclude("prefix:/api/admin")
  .build(MetricCollectingService.newDecorator());
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now update the service bindings without restarting a `}<a parentName="p" {...{
            "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
          }}>{`type://Server`}</a>{` using
`}<a parentName="p" {...{
            "href": "type://Server#reconfigure(ServerConfigurator):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html#reconfigure(com.linecorp.armeria.server.ServerConfigurator)"
          }}>{`type://Server#reconfigure(ServerConfigurator)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3041"
          }}>{`#3041`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3362"
          }}>{`#3362`}</a>{` Note that there are some limitations on
the `}<a parentName="p" {...{
            "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
          }}>{`type://Server`}</a>{` reconfiguration. Please check out `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3041"
          }}>{`#3041`}</a>{` for details.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server server = ...;
server.reconfigure(sb -> {
   sb.service("/dynamic", new LazyService());
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://RequestOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/RequestOptions.html"
          }}>{`type://RequestOptions`}</a>{` to configure various options at request level. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3593"
          }}>{`#3593`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpRequest req = ...;
WebClient client = ...;
RequestOptions options =
    RequestOptions.builder()
                  .responseTimeout(Duration.ofSeconds(20))
                  .writeTimeout(Duration.ofSeconds(5))
                  .maxResponseLength(2048)
                  .attr(foo, "bar")
                  .build();
// Specify RequestOptions when sending a request.
client.execute(req, options);

// You can also specify the options when building a request
// using WebClientRequestPreparation.
client.prepare()
      .get("/some/path")
      .responseTimeout(Duration.ofSeconds(20))
      .writeTimeout(Duration.ofSeconds(5))
      .maxResponseLength(2048)
      .attr(foo, "bar")
      .execute();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now fluently build a `}<a parentName="p" {...{
            "href": "type://BlockingTaskExecutor:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/BlockingTaskExecutor.html"
          }}>{`type://BlockingTaskExecutor`}</a>{` using `}<a parentName="p" {...{
            "href": "type://BlockingTaskExecutorBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/BlockingTaskExecutorBuilder.html"
          }}>{`type://BlockingTaskExecutorBuilder`}</a>{`.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3315"
          }}>{`#3315`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3389"
          }}>{`#3389`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`BlockingTaskExecutor.builder()
                    .daemon(true)
                    .numThreads(500)
                    .threadNamePrefix("my-blocking-task-executor")
                    .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now easily get a boolean value from `}<a parentName="p" {...{
            "href": "type://QueryParams:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/QueryParams.html"
          }}>{`type://QueryParams`}</a>{` and `}<a parentName="p" {...{
            "href": "type://HttpHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaders.html"
          }}>{`type://HttpHeaders`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3600"
          }}>{`#3600`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpHeaders headers = HttpHeaders.of("checked", "true", "valid", "1");
assert headers.getBoolean("checked");
assert headers.getBoolean("valid");
QueryParams params = QueryParams.of("checked", "false", "valid", "0");
assert !params.getBoolean("checked");
assert !params.getBoolean("valid");
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now conveniently configure a loopback port with
`}<a parentName="p" {...{
            "href": "type://ServerBuilder#localPort(int,Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#localPort(int,java.lang.Iterable)"
          }}>{`type://ServerBuilder#localPort(int,Iterable)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3599"
          }}>{`#3599`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3601"
          }}>{`#3601`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .localPort(8080)
      .localPort(9090, SessionProtocol.HTTP, SessionProtocol.HTTPS);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`All Jackson modules in the class loader are registered automatically by default via Jackson's
`}<a parentName="p" {...{
            "href": "https://fasterxml.github.io/jackson-databind/javadoc/2.11/com/fasterxml/jackson/databind/cfg/MapperBuilder.html#findAndAddModules--"
          }}>{`built-in SPI mechanism`}</a>{`
for your convenience.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`You can override this behavior by registering your `}<a parentName="li" {...{
              "href": "type://JacksonModuleProvider"
            }}>{`type://JacksonModuleProvider`}</a>{` implementation
via Java SPI. `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/3594"
            }}>{`#3594`}</a>{` `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/3625"
            }}>{`#3625`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://datatracker.ietf.org/doc/html/rfc5482"
          }}>{`TCP user timeout option`}</a>{` is now enabled by default when an
idle timeout is enabled. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3509"
          }}>{`#3509`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`TCP_KEEPIDLE`}</inlineCode>{` and `}<inlineCode parentName="li">{`TCP_KEEPINTVL`}</inlineCode>{` options are enabled when a PING interval is greater than 0.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now get the last value of a header or a query parameter using
`}<a parentName="p" {...{
            "href": "type://HttpHeaders#getLast(CharSequence):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaders.html#getLast(java.lang.CharSequence)"
          }}>{`type://HttpHeaders#getLast(CharSequence)`}</a>{` and `}<a parentName="p" {...{
            "href": "type://QueryParams#getLast(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/QueryParams.html#getLast(java.lang.String)"
          }}>{`type://QueryParams#getLast(String)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3438"
          }}>{`#3438`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3568"
          }}>{`#3568`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpHeaders headers =
    HttpHeaders.builder()
               .add(HttpHeaderNames.X_FORWARDED_FOR,
                   List.of("203.0.113.195", "150.172.238.178"))
               .build();
assert headers.getLast(HttpHeaderNames.X_FORWARDED_FOR)
              .equals("150.172.238.178");

QueryParams params = 
    QueryParams.builder()
               .add("items", List.of("1", "2", "3"))
               .build();
assert params.getLastInt("items").equals(3);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://ContentTooLargeException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/ContentTooLargeException.html"
          }}>{`type://ContentTooLargeException`}</a>{` now provides you the following 3 properties about why
the exception was raised. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3520"
          }}>{`#3520`}</a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3616"
          }}>{`#3616`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`The maximum allowed content length`}</li>
          <li parentName="ul">{`The content length specified in the `}<inlineCode parentName="li">{`content-length`}</inlineCode>{` header`}</li>
          <li parentName="ul">{`The number of bytes transferred so far`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now convert a `}<a parentName="p" {...{
            "href": "type://Multipart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html"
          }}>{`type://Multipart`}</a>{` into an `}<a parentName="p" {...{
            "href": "type://HttpResponse:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html"
          }}>{`type://HttpResponse`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3642"
          }}>{`#3642`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now warm up an `}<inlineCode parentName="p">{`EventLoopGroup`}</inlineCode>{` using `}<a parentName="p" {...{
            "href": "type://EventLoopGroups#warmUp(EventLoopGroup):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/EventLoopGroups.html#warmUp(io.netty.channel.EventLoopGroup)"
          }}>{`type://EventLoopGroups#warmUp(EventLoopGroup)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3363"
          }}>{`#3363`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3610"
          }}>{`#3610`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now figure out the number of connections managed by a `}<a parentName="p" {...{
            "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
          }}>{`type://ClientFactory`}</a>{` with
`}<a parentName="p" {...{
            "href": "type://ClientFactory#numConnections():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html#numConnections()"
          }}>{`type://ClientFactory#numConnections()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3596"
          }}>{`#3596`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3613"
          }}>{`#3613`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify the number of event loop threads and blocking task threads more conveniently. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3597"
          }}>{`#3597`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3602"
          }}>{`#3602`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .workerGroup(3)
      .blockingTaskExecutor(10)
      ...
      .build();

ClientFactory.builder()
             .workerGroup(3)
             .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now clear the mocking state of a `}<a parentName="p" {...{
            "href": "type://MockWebServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/mock/MockWebServerExtension.html"
          }}>{`type://MockWebServerExtension`}</a>{` using
`}<a parentName="p" {...{
            "href": "type://MockWebServerExtension#reset():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/mock/MockWebServerExtension.html#reset()"
          }}>{`type://MockWebServerExtension#reset()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3629"
          }}>{`#3629`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Various optimizations for `}<a parentName="li" {...{
          "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
        }}>{`type://StreamMessage`}</a>{` implementations. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3585"
        }}>{`#3585`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3584"
        }}>{`#3584`}</a></li>
      <li parentName="ul">{`You can now use an optimized context-aware `}<inlineCode parentName="li">{`CompletableFuture`}</inlineCode>{` for Java 12 and above. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2119"
        }}>{`#2119`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3615"
        }}>{`#3615`}</a></li>
      <li parentName="ul">{`Various multipart MIME types were added to `}<a parentName="li" {...{
          "href": "type://MediaType:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/MediaType.html"
        }}>{`type://MediaType`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3642"
        }}>{`#3642`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ByteBuf`}</inlineCode>{`s are no longer leaked when using:`}
        <ul parentName="li">
          <li parentName="ul">{`Spring WebFlux integration with compression `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/3608"
            }}>{`#3608`}</a>{` `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/3617"
            }}>{`#3617`}</a>{`; or`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://StreamMessageDuplicator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessageDuplicator.html"
            }}>{`type://StreamMessageDuplicator`}</a>{` `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/3476"
            }}>{`#3476`}</a>{` `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/3583"
            }}>{`#3583`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`You no longer see a `}<a parentName="li" {...{
          "href": "type://ResponseTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ResponseTimeoutException.html"
        }}>{`type://ResponseTimeoutException`}</a>{` with `}<a parentName="li" {...{
          "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
        }}>{`type://CircuitBreakerClient`}</a>{` when a large number
of messages are received from a response. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3631"
        }}>{`#3631`}</a></li>
      <li parentName="ul">{`You can now set the service name of an annotated service using
the `}<a parentName="li" {...{
          "href": "type://ServerBuilder#defaultServiceNaming(ServiceNaming):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#defaultServiceNaming(com.linecorp.armeria.server.ServiceNaming)"
        }}>{`type://ServerBuilder#defaultServiceNaming(ServiceNaming)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3589"
        }}>{`#3589`}</a></li>
      <li parentName="ul">{`You no longer see a `}<a parentName="li" {...{
          "href": "type://ClosedStreamException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/ClosedStreamException.html"
        }}>{`type://ClosedStreamException`}</a>{` when an `}<a parentName="li" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{` is empty. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3381"
        }}>{`#3381`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3636"
        }}>{`#3636`}</a></li>
      <li parentName="ul">{`Late `}<inlineCode parentName="li">{`Subscriber`}</inlineCode>{`s correctly run on the specified executor. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3623"
        }}>{`#3623`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3632"
        }}>{`#3632`}</a></li>
      <li parentName="ul">{`An exception raised in `}<a parentName="li" {...{
          "href": "type://THttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpService.html"
        }}>{`type://THttpService`}</a>{` is now properly peeled using
`}<a parentName="li" {...{
          "href": "type://Exceptions#peel(Throwable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/Exceptions.html#peel(java.lang.Throwable)"
        }}>{`type://Exceptions#peel(Throwable)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3588"
        }}>{`#3588`}</a></li>
      <li parentName="ul">{`You no longer see an `}<inlineCode parentName="li">{`UnsatisfiedLinkError`}</inlineCode>{` when a Netty JAR without native libraries is included. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3485"
        }}>{`#3485`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3607"
        }}>{`#3607`}</a></li>
      <li parentName="ul">{`Additional headers are now correctly updated to `}<a parentName="li" {...{
          "href": "type://RequestOnlyLog#requestHeaders():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestOnlyLog.html#requestHeaders()"
        }}>{`type://RequestOnlyLog#requestHeaders()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3579"
        }}>{`#3579`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3581"
        }}>{`#3581`}</a></li>
      <li parentName="ul">{`A timeout is more precisely scheduled for a request or a response. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3570"
        }}>{`#3570`}</a></li>
      <li parentName="ul">{`You no longer see a duplicate `}<inlineCode parentName="li">{`Content-Type`}</inlineCode>{` header when using `}<a parentName="li" {...{
          "href": "type://Multipart:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/Multipart.html"
        }}>{`type://Multipart`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3642"
        }}>{`#3642`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Bouncy Castle 1.68 → 1.69`}</li>
      <li parentName="ul">{`Dropwizard 2.0.21 → 2.0.23`}</li>
      <li parentName="ul">{`Dropwizard Metrics 4.1.21 → 4.2.2`}</li>
      <li parentName="ul">{`Eureka 1.10.13 → 1.10.15`}</li>
      <li parentName="ul">{`gRPC-Java 1.37.0 → 1.38.1`}</li>
      <li parentName="ul">{`gRPC-Kotlin 1.0.0 → 1.1.0`}</li>
      <li parentName="ul">{`JBoss logging 3.4.1 → 3.4.2`}</li>
      <li parentName="ul">{`Jetty 9.4.39 → 9.4.42`}</li>
      <li parentName="ul">{`Kotlin 1.5.0 → 1.5.10`}</li>
      <li parentName="ul">{`Kotlin Coroutines 1.4.3 → 1.5.0`}</li>
      <li parentName="ul">{`Netty 4.1.63 → 4.1.65`}</li>
      <li parentName="ul">{`netty-tcnative-boringssl-static 2.0.38 → 2.0.39`}</li>
      <li parentName="ul">{`Prometheus 0.10.0 → 0.11.0`}</li>
      <li parentName="ul">{`Reactor 3.4.6 → 3.4.7`}</li>
      <li parentName="ul">{`RESTEasy 4.6.0 → 4.6.1`}</li>
      <li parentName="ul">{`RxJava 3.0.12 → 3.0.13`}</li>
      <li parentName="ul">{`Scala`}
        <ul parentName="li">
          <li parentName="ul">{`2.12.13 → 2.12.14`}</li>
          <li parentName="ul">{`2.13.5 → 2.13.6`}</li>
        </ul>
      </li>
      <li parentName="ul">{`ScalaPB runtime 0.11.2 → 0.11.3`}</li>
      <li parentName="ul">{`ScalaPB json4s 0.11.0 → 0.11.1`}</li>
      <li parentName="ul">{`SLF4J 1.7.30 → 1.7.31`}</li>
      <li parentName="ul">{`Spring 5.3.7 → 5.3.8`}</li>
      <li parentName="ul">{`Spring Boot 2.4.5 → 2.5.1`}</li>
      <li parentName="ul">{`Tomcat`}
        <ul parentName="li">
          <li parentName="ul">{`8.5.64 → 8.5.68`}</li>
          <li parentName="ul">{`9.0.44 → 9.0.46`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Thrift 0.14.1 → 0.14.2`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['amitvc', 'anuraaga', 'haithamgabr', 'heowc', 'hexoul', 'hyangtack', 'ikhoon', 'jrhee17', 'JunoJunho', 'KarboniteKream', 'kezhenxu94', 'kojilin', 'mauhiz', 'max904-github', 'minwoox', 'nirvanarsc', 'serajin00', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      